import React, { useEffect, useState } from "react";
import { auth, db } from "./Firebase";
import { doc, getDoc } from "firebase/firestore";
import { AiOutlineClose } from "react-icons/ai"; // Import AiOutlineClose

function Profile({ onClose }) {
  const [userDetails, setUserDetails] = useState(null);

  const fetchUserData = async () => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setUserDetails(docSnap.data());
        } else {
          console.log("User data not found");
        }
      } else {
        console.log("No user logged in");
      }
    });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  async function handleLogout() {
    try {
      await auth.signOut();
      onClose(); // Close the modal after logout
      console.log("User logged out successfully!");
    } catch (error) {
      console.error("Error logging out:", error.message);
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-900"
        >
          <AiOutlineClose size={24} />
        </button>
        {userDetails ? (
          <div className="flex flex-col items-center">
            <div className="flex justify-center mb-4">
              <img
                src={userDetails.photo}
                alt="User"
                className="w-32 h-32 rounded-full"
              />
            </div>
            <h3 className="text-xl font-semibold mb-2">Welcome {userDetails.firstName} 🙏🙏</h3>
            <div className="text-left mb-4">
              <p className="mb-2"><strong>Email:</strong> {userDetails.email}</p>
              <p className="mb-2"><strong>Full Name:</strong> {userDetails.firstName}</p>
            </div>
            <button
              onClick={handleLogout}
              className="w-full bg-red-600 text-white py-2 rounded shadow hover:bg-red-700 transition"
            >
              Logout
            </button>
          </div>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default Profile;
