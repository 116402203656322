import React, { useState, useEffect } from "react";
import NisargaDairyIcon from '../assets/NisargaDairyicon/NisargaIcon.png';
import { Link, useLocation } from "react-router-dom";
import { AiOutlineMenuUnfold, AiOutlineClose } from "react-icons/ai";
import { FaHome } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import CiLocation from "./Location/CiLocation";
import SearchBar from "./SeachBar/SearchBar";
import ContactUs from './Contactus/Contactus';
import ProductList from "./DairyProducts/ProductList";
import LoginModal from './Login/LoginModel';
import Profile from './Login/Profile'; 
import { auth } from './Login/Firebase'; 

const Navbar = ({ setHeaderText }) => {
  const [menu, setMenu] = useState(false);
  const [pincode, setPincode] = useState('572022');
  const [showContactUs, setShowContactUs] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [newPincode, setNewPincode] = useState(pincode);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname === '/homepage') {
      setShowProducts(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(setUser);
    return () => unsubscribe();
  }, []);

  const handleChange = () => {
    setMenu(!menu);
    if (menu) {
      setShowProducts(false);
    }
  };

  const closeMenu = () => {
    setMenu(false);
    setShowProducts(false);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setError(''); // Reset error message when the popup is toggled
  };

  const handlePincodeChange = (e) => {
    const { value } = e.target;
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      setNewPincode(value);
      setError(''); // Clear error message when valid input is entered
    }
  };

  const handleSubmit = () => {
    if (newPincode.length === 6) {
      setPincode(newPincode);
      togglePopup();
    } else {
      setError('Please enter a valid 6-digit pincode.');
    }
  };

  const toggleContactUs = () => {
    setShowContactUs(prevShow => !prevShow);
  };

  const handleProductSelect = (productName) => {
    setHeaderText(productName);
    closeMenu();
  };

  const handleProductsClick = () => {
    setShowProducts(prevShow => !prevShow);
  };

  const handleHomeClick = () => {
    window.location.href = '/homepage';
  };

  const handleProfileClick = () => {
    if (user) {
      setShowProfile(prevState => !prevState);
      setShowLoginModal(false);
    } else {
      setShowLoginModal(true);
      setShowProfile(false);
    }
  };

  const closeLoginModal = () => {
    setShowLoginModal(false);
    setShowProfile(false);
  };

  return (
    <div className="fixed w-full z-10 mb-6">
      <div className="bg-gradient-to-r from-backgroundColor to-brightColor shadow-md">
        <div className="flex flex-col md:flex-row justify-between items-center px-4 py-3 md:py-5 mx-auto max-w-screen-xl">
          <div className="flex flex-row justify-between items-center w-full md:w-auto">
            <div className="md:hidden flex items-center">
              <div className="flex flex-row items-center ml-0">
                <img
                  src={NisargaDairyIcon}
                  alt="Nisarga Dairy"
                  className="h-10 w-10"
                />
                <h1 className="text-md font-semibold ml-2">NisargaDairy</h1>
              </div>
            </div>

            <div className="hidden md:flex flex-row items-center ml-2">
              <img
                src={NisargaDairyIcon}
                alt="Nisarga Dairy"
                className="h-12 w-15"
              />
              <h1 className="text-lg md:text-xl font-semibold ml-2">NisargaDairy</h1>
              <CiLocation currentPincode={pincode} updatePincode={setPincode} />
            </div>

            <div className="md:hidden flex items-center">
              <FaHome size={28} onClick={handleHomeClick} className="cursor-pointer ml-6" />
              {menu ? (
                <AiOutlineClose size={25} onClick={handleChange} />
              ) : (
                <AiOutlineMenuUnfold size={25} onClick={handleChange} />
              )}
            </div>
          </div>

          <div className="hidden md:flex items-center gap-6">
            <div className="relative">
              <SearchBar className="w-32" /> {/* Adjust width here */}
            </div>
            <nav className="flex flex-row items-center text-lg font-medium gap-4 md:gap-8">
              <Link to='/homepage' className="group relative inline-block cursor-pointer">
                <FaHome size={38} className="md:size-15" /> 
              </Link>
              <div
                className="group relative inline-block cursor-pointer text-sm md:text-base"
                onClick={toggleContactUs}
              >
                ContactUs
              </div>

              <div 
                className="group relative inline-block cursor-pointer"
                onClick={handleProfileClick}
              >
                {user ? (
                  <img
                    src={user.photoURL} 
                    alt="User"
                    className="w-6 h-6 md:w-8 md:h-8 rounded-full"
                  />
                ) : (
                  <CgProfile size={28} className="md:size-15" /> 
                )}
              </div>
            </nav>
          </div>
        </div>

        {isMobile && (
          <div className="flex items-center px-3 py-1">
            <CiLocation currentPincode={pincode} updatePincode={setPincode} />
            <div className="ml-auto text-sm md:text-base cursor-pointer" onClick={togglePopup}>
              Change Pincode
            </div>
          </div>
        )}

        <div
          className={`${
            menu ? "translate-x-0" : "-translate-x-full"
          } lg:hidden flex flex-col absolute bg-black text-white left-0 top-[calc(64px + 50px)] font-semibold text-left pt-5 pb-10 gap-4 transition-transform duration-300 overflow-y-auto`}
          style={{ width: '75vw', height: 'calc(100vh - 64px)' }}
        >
          <div className="flex flex-col items-start px-5">
            {user ? (
              <span
                className="flex items-center gap-2 mb-2"
                onClick={() => {
                  closeMenu();
                  setShowProfile(true);
                }}
              >
                <img
                  src={user.photoURL} 
                  alt="User"
                  className="w-8 h-8 rounded-full"
                />
                <span className="text-sm md:text-base">Logout</span>
              </span>
            ) : (
              <span
                className="flex items-center gap-2 cursor-pointer mb-2"
                onClick={() => {
                  closeMenu();
                  setShowLoginModal(true);
                }}
              >
                <CgProfile size={28} className="md:size-32" />
                <span className="text-sm md:text-base">Login</span> 
              </span>
            )}

            <div className="border-t border-gray-600 my-4 w-full" />

            <span
              className="text-sm md:text-base font-semibold mb-1 cursor-pointer"
              onClick={handleProductsClick}
            >
              Dairy Products
            </span>
            <div className="border-t border-gray-600 my-2 w-full" />
            {showProducts && (
              <div className="flex flex-col gap-4 px-4 py-2">
                <ProductList onProductSelect={handleProductSelect} isMobile={isMobile} />
                <div className="border-t border-gray-600 my-1 w-full" />
              </div>
            )}

            <span
              className="text-sm md:text-base font-semibold mb-1 cursor-pointer"
              onClick={toggleContactUs}
            >
              Contact Us
            </span>
          </div>
        </div>
      </div>
      
      {!isMobile && !location.pathname.includes('/homepage') && (
        <div className="bg-white shadow-md">
          <div className="max-w-screen-xl mx-auto px-4 py-2">
            <ProductList onProductSelect={handleProductSelect} isMobile={isMobile} />
          </div>
        </div>
      )}

      {/* Popup for pincode change */}
      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enter New Pincode</h2>
            <input
              type="text"
              value={newPincode}
              onChange={handlePincodeChange}
              maxLength="6"
              className="border p-2 rounded w-full"
              placeholder="Enter 6-digit pincode"
            />
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <div className="flex justify-end mt-4">
              <button onClick={togglePopup} className="bg-gray-300 p-2 rounded mr-2">Cancel</button>
              <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded">Submit</button>
            </div>
          </div>
        </div>
      )}

      {/* Contact Us Modal */}
      {showContactUs && <ContactUs onClose={() => setShowContactUs(false)} isMobile={isMobile} />}
      
      {/* Login Modal */}
      <LoginModal isOpen={showLoginModal} onClose={closeLoginModal} />
      
      {/* Profile Modal */}
      {showProfile && <Profile onClose={() => setShowProfile(false)} />}
    </div>
  );
};

export default Navbar;
