import React from 'react';
import useWindowSize from '../useWindowSize'; // Adjust the path as needed

const HeroProduct = ({ image, name, rating, mrp, discount, viewMode, showDiscount }) => {
  const { width } = useWindowSize();
  const isMobile = width < 640; // Adjust the breakpoint as needed
  const isListMode = viewMode === 'list'; // Check if list mode is active
  const isGridMode = viewMode === 'grid'; // Check if grid mode is active

  const calculateDiscountedPrice = (price, discount) => {
    return price - (price * discount) / 100;
  };

  const originalPrice = parseFloat(mrp.replace('₹', ''));
  const discountedPrice = calculateDiscountedPrice(originalPrice, discount);

  return (
    <div
      className={`bg-white shadow-md rounded-lg overflow-hidden ${
        isMobile
          ? isListMode
            ? 'flex flex-col items-center'
            : 'flex flex-row'
          : isListMode
          ? 'flex flex-col items-center'
          : 'flex flex-col'
      }`}
    >
      {/* Image */}
      <img
        src={image || 'default-image.jpg'} // Use a default image if `image` is missing
        alt={name || 'Product image'} // Fallback text if `name` is missing
        className={`object-cover ${
          isMobile
            ? isListMode
              ? 'w-full h-30'
              : 'w-40 h-45' // Mobile sizes
            : isListMode
            ? 'w-60 h-50'
            : isGridMode
            ? 'w-full h-60' // Grid mode height adjustment
            : 'w-full h-48' // Default desktop size
        }`}
      />

      {/* Product Details */}
      <div
        className={`p-2 ${
          isMobile
            ? isListMode
              ? 'text-center'
              : 'text-center'
            : isListMode
            ? 'text-center'
            : 'text-center'
        }`}
      >
        <h3
          className={`text-sm ${
            isMobile
              ? isListMode
                ? 'text-base'
                : 'text-lg'
              : isListMode
              ? 'text-lg'
              : 'text-lg'
          } font-semibold mb-1`}
        >
          {name || 'Product Name'} {/* Fallback text if `name` is missing */}
        </h3>
        <div className="flex items-center justify-center mt-1">
          <span
            className={`text-yellow-500 ${
              isMobile
                ? isListMode
                  ? 'text-lg'
                  : 'text-lg'
                : isListMode
                ? 'text-lg'
                : 'text-lg'
            }`}
            aria-label="Product Rating"
          >
            ★
          </span>
          <span
            className={`ml-1 ${
              isMobile
                ? isListMode
                  ? 'text-base'
                  : 'text-base'
                : isListMode
                ? 'text-base'
                : 'text-base'
            } text-gray-700`}
          >
            {rating || 'No Rating'} {/* Fallback text if `rating` is missing */}
          </span>
        </div>
        <div className="mt-2 text-gray-900 font-bold">
          <span>
            MRP: {mrp || 'Price Not Available'} {/* MRP label added here */}
          </span>
          {showDiscount && (
            <span className="text-red-600 ml-2">(₹{discountedPrice.toFixed(2)})</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroProduct;
