import milk1 from '../../assets/ProductsList/MilkProducts/Milk1.jpg';
import milk2 from '../../assets/ProductsList/MilkProducts/Milk1.jpg';
import milk3 from '../../assets/ProductsList/MilkProducts/Milk1.jpg';
import milk4 from '../../assets/ProductsList/MilkProducts/Milk1.jpg';

const MilkProducts = [
  { id: 4, image: milk1, name: 'Milk Product 1', rating: 4.7, mrp: '₹150' ,discount: 10},
  { id: 5, image: milk2, name: 'Milk Product 2', rating: 4.2, mrp: '₹120',discount: 10 },
  { id: 6, image: milk3, name: 'Milk Product 3', rating: 4.2, mrp: '₹130',discount: 10 },
  { id: 7, image: milk4, name: 'Milk Product 4', rating: 4.2, mrp: '₹140',discount: 10 },
];

export default MilkProducts;
