import React from 'react';
import HomeImageSlider from './HomeImageSlider'; 
import HomeSliderImageList from './HomeSliderImageList'; 
import HomeProducts from './HomeProducts/HomeProducts';
import HomePageProductList from './HomeProductsList/HomePageProductList';
import HomeProductItem from './HomeProducts/HomeProdutItem'; 
import Footer from '../../../components/Footer'

const HomePage = () => {
  const slides = HomeSliderImageList.images; // Ensure this is correctly provided

  return (
    <>
      <div className="flex flex-row justify-between items-center px-4 py-5 mx-auto max-w-screen-xl">
        <HomeImageSlider slides={slides} />
      </div>
      <div>
        <HomeProductItem products={HomeProducts} />  {/* Correctly pass `products` prop */}
      </div>
      <div>
        <HomePageProductList />
      </div>
      <div>
        <Footer/>
      </div>
    </>
  );
};

export default HomePage;
