import React, { useState, useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Login from './Login';
import Register from './Register';
import Profile from './Profile';
import { auth } from './Firebase'; // Ensure Firebase is correctly imported and initialized

const LoginModal = ({ isOpen, onClose }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setIsRegistering(false); // Reset registration state when modal closes
      setIsLoggedIn(false); // Reset login state when modal closes
    }
  }, [isOpen]);

  const handleSwitch = () => {
    setIsRegistering(!isRegistering); // Toggle between login and register forms
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-md mx-auto p-6 relative overflow-auto">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
        >
          <AiOutlineClose size={24} />
        </button>
        {isLoggedIn ? (
          <Profile onClose={onClose} /> // Show Profile component if logged in
        ) : (
          <>
            {isRegistering ? (
              <Register onSwitch={handleSwitch} />
            ) : (
              <Login onSwitch={handleSwitch} onLoginSuccess={onClose} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoginModal;
