import React, { useState } from 'react';
import { CiLocationOn } from 'react-icons/ci';

const CiLocation = ({ currentPincode, updatePincode }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [pincode, setPincode] = useState(currentPincode);
  const [error, setError] = useState('');

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setError(''); // Reset error message when the popup is toggled
  };

  const handlePincodeChange = (e) => {
    const { value } = e.target;
    if (value.length <= 6 && /^[0-9]*$/.test(value)) {
      setPincode(value);
      setError(''); // Clear error message when valid input is entered
    }
  };

  const handleSubmit = () => {
    if (pincode.length === 6) {
      updatePincode(pincode);
      togglePopup();
    } else {
      setError('Please enter a valid 6-digit pincode.');
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center cursor-pointer gap-1 ml-1" onClick={togglePopup}>
        <span>
          <CiLocationOn size={23} />
        </span>
        <h1 className="text-sm font-normal">{currentPincode}</h1>
        <h1 className="hidden md:flex text-sm font-normal">(Change Pincode)</h1>
      </div>

      {showPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-8 rounded shadow-lg">
            <h2 className="text-xl font-semibold mb-4">Enter New Pincode</h2>
            <input
              type="text"
              value={pincode}
              onChange={handlePincodeChange}
              maxLength="6"
              className="border p-2 rounded w-full"
              placeholder="Enter 6-digit pincode"
            />
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <div className="flex justify-end mt-4">
              <button onClick={togglePopup} className="bg-gray-300 p-2 rounded mr-2">Cancel</button>
              <button onClick={handleSubmit} className="bg-blue-500 text-white p-2 rounded">Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CiLocation;
