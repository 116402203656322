import React from 'react';
import HeroProduct from './HeroProduct';
import MilkProducts from './HeroProductImageList/MilkProducts';
import CheeseProducts from './HeroProductImageList/CheeseProducts';
import ButterProducts from './HeroProductImageList/ButterProducts';
import CurdProducts from './HeroProductImageList/CurdProducts';
import FullCreamMilkProducts from './HeroProductImageList/FullCreamMilk';

// Mapping product categories to their respective data
const allProducts = {
  Milk: MilkProducts,
  Cheese: CheeseProducts,
  Butter: ButterProducts,
  Curd:CurdProducts,
  FullCreamMilk:FullCreamMilkProducts,
  // Add more product categories here...
};

const HeroProductImageList = ({ headerText, viewMode, sorting }) => {
  // Retrieve the products based on the headerText
  let products = headerText ? [...(allProducts[headerText] || [])] : [];

  // Sorting logic based on selected sorting option
  switch (sorting) {
    case 'Price High to Low':
      products.sort((a, b) => parseFloat(b.mrp.replace('₹', '')) - parseFloat(a.mrp.replace('₹', '')));
      break;
    case 'Price Low to High':
      products.sort((a, b) => parseFloat(a.mrp.replace('₹', '')) - parseFloat(b.mrp.replace('₹', '')));
      break;
    case 'Best Selling':
      // Implement sorting logic for 'Best Selling' if needed
      break;
    case 'Latest Arrivals':
      // Implement sorting logic for 'Latest Arrivals' if needed
      break;
    case 'Discount':
      // Implement sorting logic for 'Discount' if needed
      break;
    default:
      break;
  }

  return (
    <div
      className={`p-4 ml-0 md:mr-20 md:ml-20 ${
        viewMode === 'list'
          ? 'grid grid-cols-2 gap-4'
          : 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'
      }`}
    >
      {products.map((product, index) => (
        <HeroProduct
          key={index}
          image={product.image}
          name={product.name}
          rating={product.rating}
          mrp={product.mrp}
          discount={product.discount}
          viewMode={viewMode}
          showDiscount={sorting === 'Discount'} // Pass discount visibility state
        />
      ))}
    </div>
  );
};

export default HeroProductImageList;
