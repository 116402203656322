import { useState, useEffect, useCallback } from "react";

export default function HomeImageSlider({ slides }) {
  const [current, setCurrent] = useState(0);

  // Function to move to the previous slide
  const previousSlide = useCallback(() => {
    setCurrent((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  }, [slides.length]);

  // Function to move to the next slide
  const nextSlide = useCallback(() => {
    setCurrent((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
  }, [slides.length]);

  // Automatically move slides every 5 seconds
  useEffect(() => {
    const interval = setInterval(nextSlide, 5000); // 5000ms = 5 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="relative overflow-hidden fixed-height">
      <div
        className="flex transition-transform ease-out duration-500"
        style={{
          transform: `translateX(-${current * 100}%)`,
        }}
      >
        {slides.map((s, index) => (
          <img src={s} alt={`Slide ${index + 1}`} key={index} className="w-full rounded-lg object-contain" />
        ))}
      </div>

      <button
        onClick={previousSlide}
        className="absolute top-1/2 left-0 transform -translate-y-1/2 text-white text-lg sm:text-xl md:text-2xl lg:text-3xl p-1 bg-black bg-opacity-50 rounded-full"
        style={{ zIndex: 1 }}
      >
        &lt;
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-0 transform -translate-y-1/2 text-white text-lg sm:text-xl md:text-2xl lg:text-3xl p-1 bg-black bg-opacity-50 rounded-full"
        style={{ zIndex: 1 }}
      >
        &gt;
      </button>

      <div className="absolute bottom-4 w-full flex justify-center gap-2">
        {slides.map((_, i) => (
          <div
            onClick={() => setCurrent(i)}
            key={"circle" + i}
            className={`rounded-full w-2 h-2 cursor-pointer ${
              i === current ? "bg-white" : "bg-gray-500"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}
