import curd1 from '../../assets/ProductsList/CurdProducts/Curd1.jpg';
import curd2 from '../../assets/ProductsList/CurdProducts/Curd1.jpg';
import curd3 from '../../assets/ProductsList/CurdProducts/Curd1.jpg';
import curd4 from '../../assets/ProductsList/CurdProducts/Curd1.jpg';

const CurdProducts = [
  { id: 4, image: curd1, name: 'Curd Product 1', rating: 4.7 ,mrp: '₹140',discount: 10},
  { id: 5, image: curd2, name: 'Curd Product 2', rating: 4.2 ,mrp: '₹140',discount: 10},
  { id: 6, image: curd3, name: 'Curd Product 3', rating: 4.2 ,mrp: '₹140',discount: 10},
  { id: 7, image: curd4, name: 'Curd Product 4', rating: 4.2 ,mrp: '₹140',discount: 10},
];

export default CurdProducts;
