// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore"



const firebaseConfig = {
  apiKey: "AIzaSyD4_k7N36teIQ3hPz1isEASgUwPgNYAJOM",
  authDomain: "loginform-940af.firebaseapp.com",
  projectId: "loginform-940af",
  storageBucket: "loginform-940af.appspot.com",
  messagingSenderId: "88113333497",
  appId: "1:88113333497:web:69147322abeb8af3248e28",
  measurementId: "G-CSE8X2V22Q"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth=getAuth(app);
export const db=getFirestore(app);
export default app;