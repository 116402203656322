import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./Firebase";
import { toast } from "react-toastify";
import SignInWithGoogle from "./SignInWithgoogle";

function Login({ onSwitch, onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // State for error messages

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Reset error message before attempting login
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User logged in Successfully");
      if (!/\S+@\S+\.\S+/.test(email)) {
        console.error('Invalid email format');
        return;
    }
      toast.success("User logged in Successfully", {
        position: "top-center",
      });
      setEmail(""); // Reset email field
      setPassword(""); // Reset password field
      onLoginSuccess(); // Notify parent component of successful login
    } catch (error) {
      console.log(error.message);
      setError("Invalid email or password. Please try again."); // Set error message
      toast.error("Invalid email or password. Please try again.", {
        position: "bottom-center",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center justify-center w-full h-full p-4 sm:p-6 md:p-8 lg:p-10">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 sm:p-8 md:p-10 space-y-6">
        <h3 className="text-center font-semibold text-lg">Login</h3>

        {/* Display error message if it exists */}
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="email">
            Email address
          </label>
          <input
            id="email"
            type="email"
            className="form-control mt-1 block w-full rounded border-gray-300 shadow-sm focus:border-blue-600 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-1" htmlFor="password">
            Password
          </label>
          <input
            id="password"
            type="password"
            className="form-control mt-1 block w-full rounded border-gray-300 shadow-sm focus:border-blue-600 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        <div>
          <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded shadow hover:bg-blue-700 transition">
            Submit
          </button>
        </div>

        <p className="text-center text-gray-600 text-sm">
          New user?{" "}
          <span onClick={onSwitch} className="text-blue-600 cursor-pointer hover:underline">
            Register Here
          </span>
        </p>

        <SignInWithGoogle onLoginSuccess={onLoginSuccess} />
      </div>
    </form>
  );
}

export default Login;
