import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/NavigationBar/Navbar';
import HeroImageSlider from './components/Homepage/HeroImageSlider';
import ContactUs from './components/NavigationBar/Contactus/Contactus';
import Login from './components/NavigationBar/Login/Login';
import Footer from './components/Footer';
import Homepage from './components/NavigationBar/NavbarHomepage/HomePage';
import HeroCatalogHeader from './components/Homepage/HeroCatalogHeader';
import HeroProductImageList from './components/Homepage/HeroProductImageList';
import ProductPage from './components/NavigationBar/NavbarHomepage/HomeProducts/HomeProductPage';
import SortBy from './components/Homepage/SortBy'; // Import SortBy component
import useWindowSize from './components/useWindowSize'; // Ensure this is the correct path

function App() {
  const [headerText, setHeaderText] = useState('Milk');
  const [viewMode, setViewMode] = useState('grid'); // Default view mode
  const [sorting, setSorting] = useState(''); // Sorting state
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width < 768; // Adjust breakpoint as needed

  const toggleSorting = () => setIsSortingOpen(!isSortingOpen);

  return (
    <Router>
      <Navbar setHeaderText={setHeaderText} />
      <div className="pt-16 sm:pt-5 md:pt-10 lg:pt-24"> {/* Responsive padding top */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroImageSlider headerText={headerText} />
                <HeroCatalogHeader 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  setViewMode={setViewMode} 
                  sorting={sorting} 
                  setSorting={setSorting}
                  isSortingOpen={isSortingOpen}
                  toggleSorting={toggleSorting}
                />
                <HeroProductImageList 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  sorting={sorting} 
                />
                {/* Show SortBy below HeroProductImageList on mobile */}
                {isMobile && (
                  <SortBy
                    sorting={sorting}
                    setSorting={setSorting}
                    isSortingOpen={isSortingOpen}
                    toggleSorting={toggleSorting}
                  />
                )}
              </>
            }
          />
          <Route path="/homepage" element={<Homepage />} />
          <Route
            path="/contactus"
            element={
              <>
                <HeroCatalogHeader 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  setViewMode={setViewMode} 
                  sorting={sorting} 
                  setSorting={setSorting}
                  isSortingOpen={isSortingOpen}
                  toggleSorting={toggleSorting}
                />
                <ContactUs />
                <HeroProductImageList 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  sorting={sorting} 
                />
                {/* Show SortBy below HeroProductImageList on mobile */}
                {isMobile && (
                  <SortBy
                    sorting={sorting}
                    setSorting={setSorting}
                    isSortingOpen={isSortingOpen}
                    toggleSorting={toggleSorting}
                  />
                )}
                <Footer />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <HeroCatalogHeader 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  setViewMode={setViewMode} 
                  sorting={sorting} 
                  setSorting={setSorting}
                  isSortingOpen={isSortingOpen}
                  toggleSorting={toggleSorting}
                />
                <Login />
                <HeroProductImageList 
                  headerText={headerText} 
                  viewMode={viewMode} 
                  sorting={sorting} 
                />
                {/* Show SortBy below HeroProductImageList on mobile */}
                {isMobile && (
                  <SortBy
                    sorting={sorting}
                    setSorting={setSorting}
                    isSortingOpen={isSortingOpen}
                    toggleSorting={toggleSorting}
                  />
                )}
                <Footer />
              </>
            }
          />
          <Route
            path="/product/:productName"
            element={
              <ProductPage
                headerText={headerText}
                setHeaderText={setHeaderText}
                viewMode={viewMode}
                setViewMode={setViewMode}
                sorting={sorting}
                setSorting={setSorting}
                isSortingOpen={isSortingOpen} // Add these props if needed
                toggleSorting={toggleSorting} // Add these props if needed
              />
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
