import React from "react";

const dairyProductNames = [
  "Milk", "Cheese", "Butter", "FullCreamMilk", "Cream", "Ice Cream", "Cottage Cheese", "Buttermilk", "Ghee", "Kefir",
  "Paneer", "Whey", "Lassi", "Clotted Cream", "Curd", "Butter Milk", "Quark", "Casein", "Custard", "Condensed Milk"
];

const ProductList = ({ onProductSelect, isMobile }) => {
  const handleProductClick = (productName) => {
    if (onProductSelect) {
      onProductSelect(productName);
    }
  };

  return (
    <div
      className={`${
        isMobile ? 'flex-col' : 'flex-nowrap overflow-x-auto'
      } p-0`}
      style={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        maxWidth: isMobile ? '75vw' : 'auto', // Limit slider width to 75% of viewport width for mobile
        maxHeight: isMobile ? '90vh' : 'auto', // Limit height for mobile view
        overflow: 'hidden', // Remove scrollbar
        alignItems: isMobile ? 'flex-start' : 'center', // Align items to the left on mobile
        paddingLeft: isMobile ? '10px' : '0', // Add some left padding on mobile if needed
      }}
    >
      {dairyProductNames.map((product, index) => (
        <div
          key={index}
          className="cursor-pointer text-sm"
          onClick={() => handleProductClick(product)}
          style={{
            margin: '1px 1.98px',
            lineHeight: '1.4em',
            padding: '5.8px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start', // Align items to the left
            transition: 'color 0.3s', // Smooth transition for text color
          }}
          onMouseOver={(e) => e.currentTarget.style.color = 'lightblue'}
          onMouseOut={(e) => e.currentTarget.style.color = 'black'}
        >
          {product}
        </div>
      ))}
    </div>
  );
};

export default ProductList;
