// src/productImages.js
import Milk from '../../../assets/ProductsList/MilkProducts/Milk1.jpg'
import Cheese from '../../../assets/Image1.jpg'; // Replace with correct image path
import Butter from '../../../assets/Image3.jpg'; // Replace with correct image path
import Curd from '../../../assets/ProductsList/CurdProducts/Curd1.jpg'

const productImageMap = {
  "milk": Milk,
  "cheese": Cheese,
  "butter": Butter,
  "curd": Curd,
  "cream": Milk, 
  "ice-cream": Milk, 
  "cottage-cheese": Cheese, 
  "buttermilk": Curd, 
  "ghee": Milk, 
  "kefir": Milk, 
  "paneer": Cheese, 
  "whey": Curd, 
  "lassi": Curd, 
  "clotted-cream": Milk, 
  "butter-milk": Curd, 
  "quark": Milk, 
  "casein": Curd, 
  "custard": Milk, 
  "condensed-milk": Milk, 
};

const dairyProductNames = [
  "Milk", "Cheese", "Butter", "Curd", "Cream", "Ice Cream", "Cottage Cheese", "Buttermilk", "Ghee", "Kefir",
  "Paneer", "Whey", "Lassi", "Clotted Cream", "Curd", "Butter Milk", "Quark", "Casein", "Custard", "Condensed Milk"
];

const HomeProducts = dairyProductNames.map((name) => ({
  metafields: {
    custom_url: 0,
    category: name.toLowerCase().replace(/\s+/g, '-'), // Convert to URL-friendly format
    image: productImageMap[name.toLowerCase().replace(/\s+/g, '-')], // Get the image from the map
  }
}));

export default HomeProducts;
