import butter1 from '../../assets/Image1.jpg'
import butter2 from '../../assets/Image1.jpg'

const ButterImages = [
  {
    src: butter1,
    alt: "Butter Slide 1",
  },
  {
    src: butter2,
    alt: "Butter Slide 2",
  },
  // Add more butter product images...
];

export default ButterImages;
