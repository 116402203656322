import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import HeroCatalogHeader from '../../../Homepage/HeroCatalogHeader';
import HeroImageSlider from '../../../Homepage/HeroImageSlider';
import HeroProductImageList from '../../../Homepage/HeroProductImageList';
import SortBy from '../../../Homepage/SortBy';

function HomeProductPage({ headerText, setHeaderText, viewMode, setViewMode, sorting, setSorting, isSortingOpen, toggleSorting }) {
  const { productName } = useParams();

  useEffect(() => {
    setHeaderText(productName.charAt(0).toUpperCase() + productName.slice(1).replace('-', ' ')); // Update header text based on route parameter
  }, [productName, setHeaderText]);

  return (
    <>
      <HeroImageSlider headerText={headerText} />
      <HeroCatalogHeader 
        headerText={headerText} 
        viewMode={viewMode} 
        setViewMode={setViewMode} 
        sorting={sorting} 
        setSorting={setSorting}
        isSortingOpen={isSortingOpen}
        toggleSorting={toggleSorting}
      />
      <HeroProductImageList headerText={headerText} viewMode={viewMode} sorting={sorting} />
      {/* Display SortBy below HeroProductImageList on mobile */}
      {window.innerWidth < 768 && (
        <SortBy
          sorting={sorting}
          setSorting={setSorting}
          isSortingOpen={isSortingOpen}
          toggleSorting={toggleSorting}
        />
      )}
    </>
  );
}

export default HomeProductPage;
