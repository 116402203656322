import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, db } from "./Firebase";
import { toast } from "react-toastify";
import { setDoc, doc } from "firebase/firestore";
import googleImage from './google.png'; // Adjust the path to your image file

function SignInwithGoogle({ onLoginSuccess }) {
  const googleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const docRef = doc(db, "Users", user.uid);
      await setDoc(docRef, {
        email: user.email,
        firstName: user.displayName,
        photo: user.photoURL,
      });
      toast.success("Login Successfully", {
        position: "top-center",
      });
      onLoginSuccess(); // Notify parent component of successful login
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {
        position: "bottom-center",
      });
    }
  };

  return (
    <button
      className="w-full py-2 mt-4 rounded shadow transition flex items-center justify-center"
      onClick={googleLogin}
    >
      <img 
        src={googleImage} 
        alt="Sign in with Google" 
      />
    </button>
  );
}

export default SignInwithGoogle;
