import React, { useState } from 'react';
import { FaAngleDown, FaAngleUp, FaTag, FaStar, FaSortAmountDown, FaSortAmountUp, FaPercentage, FaRegCircle, FaCircle } from 'react-icons/fa';
import { HiMiniArrowsUpDown } from 'react-icons/hi2';

const SortBy = ({ sorting, setSorting, isSortingOpen, toggleSorting }) => {
  const [activeSorting, setActiveSorting] = useState(sorting);
  const [selectedOption, setSelectedOption] = useState(sorting);
  const isMobile = window.innerWidth < 768;

  const handleItemClick = (sortOption) => {
    setSorting(sortOption);
    setActiveSorting(sortOption);
    setSelectedOption(sortOption);
    toggleSorting();
  };

  const isActive = (option) => activeSorting === option;

  return (
    <div className="relative">
      <button
        className={`sort-toggle p-1 md:p-2 border rounded flex items-center ${isMobile ? 'flex-col w-full text-center' : 'justify-between items-center'}`}
        onClick={toggleSorting}
      >
        <div className="flex items-center">
        {isMobile && <HiMiniArrowsUpDown className="text-2xl" />}
        <span className="ml-1">{isMobile ? 'Sort By' : `Sort By: ${sorting || 'Select'}`}</span>
          
        </div>
        {!isMobile && (
          <i className="ml-2">
            {isSortingOpen ? <FaAngleUp /> : <FaAngleDown />}
          </i>
        )}
        {isMobile && selectedOption && (
          <span className="block text-sm mt-1 ml-5">{selectedOption}</span>
        )}
      </button>
      {isSortingOpen && (
        <ul className={`absolute ${isMobile ? 'bottom-full mb-2 w-full left-0' : 'top-full mt-2 w-48 right-0'} bg-white shadow-md border rounded z-10`}>
          <li
            className={`sortby-item p-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between ${isActive('Latest Arrivals') ? 'bg-red-100' : ''}`}
            onClick={() => handleItemClick('Latest Arrivals')}
          >
            <span className="flex items-center">
              <FaTag className="mr-2" /> Latest Arrivals
            </span>
            {isMobile && (isActive('Latest Arrivals') ? <FaCircle className="text-red-500" /> : <FaRegCircle className="text-gray-500" />)}
          </li>
          <li
            className={`sortby-item p-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between ${isActive('Best Selling') ? 'bg-red-100' : ''}`}
            onClick={() => handleItemClick('Best Selling')}
          >
            <span className="flex items-center">
              <FaStar className="mr-2" /> Best Selling
            </span>
            {isMobile && (isActive('Best Selling') ? <FaCircle className="text-red-500" /> : <FaRegCircle className="text-gray-500" />)}
          </li>
          <li
            className={`sortby-item p-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between ${isActive('Price High to Low') ? 'bg-red-100' : ''}`}
            onClick={() => handleItemClick('Price High to Low')}
          >
            <span className="flex items-center">
              <FaSortAmountDown className="mr-2" /> Price High to Low
            </span>
            {isMobile && (isActive('Price High to Low') ? <FaCircle className="text-red-500" /> : <FaRegCircle className="text-gray-500" />)}
          </li>
          <li
            className={`sortby-item p-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between ${isActive('Price Low to High') ? 'bg-red-100' : ''}`}
            onClick={() => handleItemClick('Price Low to High')}
          >
            <span className="flex items-center">
              <FaSortAmountUp className="mr-2" /> Price Low to High
            </span>
            {isMobile && (isActive('Price Low to High') ? <FaCircle className="text-red-500" /> : <FaRegCircle className="text-gray-500" />)}
          </li>
          <li
            className={`sortby-item p-2 hover:bg-gray-100 cursor-pointer flex items-center justify-between ${isActive('Discount') ? 'bg-red-100' : ''}`}
            onClick={() => handleItemClick('Discount')}
          >
            <span className="flex items-center">
              <FaPercentage className="mr-2" /> Discount
            </span>
            {isMobile && (isActive('Discount') ? <FaCircle className="text-red-500" /> : <FaRegCircle className="text-gray-500" />)}
          </li>
        </ul>
      )}
    </div>
  );
};

export default SortBy;
