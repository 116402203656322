// src/components/NavigationBar/NavbarHomepage/HomeProductsList/HomeProductList.js
import amulMilkImage from '../../../assets/Image2.jpeg'; // Adjust path if needed
import amulMilkImage1 from '../../../assets/Image1.jpg'; // Adjust path if needed

const HomeProductList = [
  {
    id: 1,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage,
    rating: 5,
    reviews: 10,
    isBestSeller: true, // Add this flag
    isNewArrival: false, // Add this flag
  },
  {
    id: 2,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage,
    rating: 5,
    reviews: 10,
    isBestSeller: true,
    isNewArrival: false,
  },
  {
    id: 3,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: false,
    isNewArrival: true, // Example for new arrivals
  },
  {
    id: 4,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: true,
    isNewArrival: false, // Example for new arrivals
  },
  {
    id: 5,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: true,
    isNewArrival: true, // Example for new arrivals
  },
  {
    id: 6,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller:true,
    isNewArrival: false, // Example for new arrivals
  },
  {
    id: 3,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: false,
    isNewArrival: true, // Example for new arrivals
  },
  {
    id: 3,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: false,
    isNewArrival: true, // Example for new arrivals
  },
  {
    id: 3,
    name: 'Amul Lactose Free Milk, 250 mL | Pack of 32',
    price: 800,
    image: amulMilkImage1,
    rating: 5,
    reviews: 10,
    isBestSeller: false,
    isNewArrival: true, // Example for new arrivals
  },
  
  // Add more products with appropriate flags
];

export default HomeProductList;
