import cheese1 from '../../assets/Image3.jpg';
import cheese2 from '../../assets/Image2.jpeg';
import cheese3 from '../../assets/Image3.jpg';
import cheese4 from '../../assets/Image2.jpeg';

const ButterProducts = [
  { id: 4, image: cheese1, name: 'Cheese Product 1', rating: 4.7, mrp: '₹300' ,discount: 10},
  { id: 5, image: cheese2, name: 'Cheese Product 2', rating: 4.2, mrp: '₹250',discount: 10 },
  { id: 6, image: cheese3, name: 'Cheese Product 3', rating: 4.2, mrp: '₹270',discount: 10 },
  { id: 7, image: cheese4, name: 'Cheese Product 4', rating: 4.2, mrp: '₹290',discount: 10 },
];

export default ButterProducts;
