import React, { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const HomePageProductCatalog = ({ image, name, rating, price }) => {
  const { width } = useWindowSize();
  const isMobile = width < 640; // Adjust the breakpoint as needed

  return (
    <div
      style={{ 
        backgroundColor: 'white', 
        borderRadius: '8px', 
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        display: isMobile ? 'flex' : 'block'
      }}
    >
      {/* Image */}
      <img
        src={image}
        alt={name}
        style={{ 
          objectFit: 'cover', 
          width: isMobile ? '128px' : '100%', 
          height: isMobile ? '128px' : '192px'
        }}
      />

      {/* Product Details */}
      <div
        style={{ 
          padding: '8px', 
          textAlign: isMobile ? 'center' : 'left'
        }}
      >
        <h3 
          style={{ 
            fontSize: isMobile ? '16px' : '20px', 
            fontWeight: '600', 
            marginBottom: '8px'
          }}
        >
          {name}
        </h3>

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '8px' }}>
          <span 
            style={{ 
              color: 'yellow', 
              fontSize: isMobile ? '16px' : '20px'
            }}
          >
            ★
          </span>
          <span 
            style={{ 
              marginLeft: '4px', 
              fontSize: isMobile ? '12px' : '16px', 
              color: 'gray'
            }}
          >
            {rating}
          </span>
        </div>

        <p 
          style={{ 
            fontSize: isMobile ? '14px' : '18px', 
            fontWeight: 'bold', 
            color: '#333'
          }}
        >
          ₹{price}
        </p>
      </div>
    </div>
  );
};

export default HomePageProductCatalog;
