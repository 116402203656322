import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const HomeProductItem = ({ products }) => {
  const [visibleProducts, setVisibleProducts] = useState(10); // Default for desktop
  const [isExpanded, setIsExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Default mobile check
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setVisibleProducts(isMobile ? 9 : 10);
  }, [isMobile]);

  const handleViewMore = () => {
    if (isExpanded) {
      setVisibleProducts(isMobile ? 9 : 10); // Collapse to show initial products based on mode
    } else {
      setVisibleProducts(products.length); // Show all products
    }
    setIsExpanded(!isExpanded); // Toggle expanded state
  };

  const handleProductClick = (category) => {
    navigate(`/product/${category}`);
  };

  const observer = useRef();

  useEffect(() => {
    const handleObserver = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fadeInUp');
        } else {
          entry.target.classList.remove('animate-fadeInUp');
        }
      });
    };

    observer.current = new IntersectionObserver(handleObserver, {
      threshold: 0.1,
    });

    const elements = document.querySelectorAll('.fade-in');
    elements.forEach((element) => observer.current.observe(element));

    return () => {
      elements.forEach((element) => observer.current.unobserve(element));
    };
  }, []);

  return (
    <div className="flex flex-col items-center px-4 py-5 mx-auto max-w-screen-xl relative">
      <h2 className="shop-category-header text-lg font-bold mb-4">Shop my category</h2>
      <h3 className="handpicked-header text-md mb-2">Handpicked for you</h3>
      <div className="grid gap-4 grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
        {products.slice(0, visibleProducts).map((record, index) => (
          record.metafields.custom_url !== 1 && (
            <div
              key={index}
              className="fade-in flex flex-col items-center cursor-pointer"
              onClick={() => handleProductClick(record.metafields.category)} // Trigger navigation
            >
              <div className="relative w-full h-40 sm:h-48 md:h-56 lg:h-64">
                <img
                  className="object-fill objct-cover w-full h-full rounded-lg"
                  src={record.metafields.image}
                  alt={record.metafields.category}
                />
                <div className="absolute inset-0 flex justify-center bg-black bg-opacity-60 text-white text-sm font-bold rounded-lg p-2">
                  <span className="text-center">{record.metafields.category}</span>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
      {products.length > (isMobile ? 9 : 10) && (
        <button
          onClick={handleViewMore}
          className="mt-5 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          {isExpanded ? 'Show Less' : 'View More'}
        </button>
      )}
      <div className="w-full">
        <div className="border-t-4 border-gray-500 mt-5"></div>
      </div>
    </div>
  );
};

export default HomeProductItem;
