// HomeSliderImageList.js

import image1 from '../../assets/Image1.jpg';  // Adjust the path as necessary
import image2 from '../../assets/Image1.jpg';
import image3 from '../../assets/Image1.jpg';
import image4 from '../../assets/Image2.jpeg';

const HomeSliderImageList = {
  images: [image1, image2, image3, image4]
};

export default HomeSliderImageList;
