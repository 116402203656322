import React, { useState } from 'react';
import { FiSearch } from 'react-icons/fi';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    // Implement search functionality
    console.log('Searching for:', searchQuery);
  };

  return (
    <div className="relative flex items-center w-full max-w-md lg:max-w-lg">
      <input
        type="text"
        className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleInputChange}
      />
      <button
        className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        onClick={handleSearch}
      >
        <FiSearch size={16} />
      </button>
    </div>
  );
};

export default SearchBar;
