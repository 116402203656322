import React, { useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const ContactUs = ({ onClose }) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');

    try {
      const response = await fetch('https://rl0ghtrspd.execute-api.us-east-1.amazonaws.com/contact/contactform', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const result = await response.json();
      if (response.ok) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        setStatus(`Error: ${result.message}`);
      }
    } catch (error) {
      setStatus(`Error: ${error.message}`);
    }
  };

  return (
    <div className="flex justify-center items-start px-2 md:px-0 md:justify-end">
      <div className="bg-cyan-700 w-full md:w-3/5 lg:w-1/2 xl:w-1/3 rounded-lg shadow-lg text-white relative overflow-y-auto max-h-[90vh]">
        <button
          className="absolute top-2 right-2 text-black text-xl font-bold focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="p-4 space-y-4">
          <div>
            <h1 className="font-bold text-2xl tracking-wide">Contact Us</h1>
            <p className="pt-2 text-sm">NisargaDairy</p>
          </div>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <FaPhoneAlt className="text-white" />
              <span>+(123) 456 7890</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaEnvelope className="text-white" />
              <span>contact@xyzwebsite.com</span>
            </div>
            <div className="flex items-center space-x-2">
              <FaMapMarkerAlt className="text-white" />
              <span>11, Street 342, Abcd fgh</span>
            </div>
            <div className="flex space-x-3 text-lg">
              <button className="hover:text-teal-300"><FaFacebook className="text-white" /></button>
              <button className="hover:text-teal-300"><FaTwitter className="text-white" /></button>
              <button className="hover:text-teal-300"><FaLinkedin className="text-white" /></button>
              <button className="hover:text-teal-300"><FaInstagram className="text-white" /></button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-lg p-4 mt-4 text-gray-600">
          <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name" className="text-sm">Your name</label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full mt-1 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-300"
              />
            </div>
            <div>
              <label htmlFor="email" className="text-sm">Your email</label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="w-full mt-1 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-300"
              />
            </div>
            <div>
              <label htmlFor="message" className="text-sm">Message</label>
              <textarea
                id="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Message"
                rows="3"
                className="w-full mt-1 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-300"
              />
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex-1">
                {status && <p className="text-red-500 text-sm">{status}</p>}
              </div>
              <button type="submit" className="bg-teal-400 text-white font-bold rounded-lg px-6 py-2 uppercase text-sm">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
