import React, { useCallback, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Importing images
import MilkImages from './HeroProductImageSlider/MilkImages';
import CheeseImages from './HeroProductImageSlider/CheeseImages';
import ButterImages from './HeroProductImageSlider/ButterImages';

const categorySlides = {
  Milk: MilkImages,
  Cheese: CheeseImages,
  Butter: ButterImages,
  // Add more categories as needed
};

const HeroImageSlider = ({ headerText }) => {
  const slides = categorySlides[headerText] || [];
  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    dots: false,
    arrows: false, // Disable default arrows
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
  };

  const nextSlide = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }, []);

  const prevSlide = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  return (
    <div className="relative mx-auto px-1 sm:px-4 lg:px-7 xl:max-w-7xl mb-10 p-4 border rounded-lg shadow-md bg-white mt-16">
      <Slider {...settings} ref={sliderRef}>
        {slides.map((slide, index) => (
          <div key={index} className="relative">
            <img
              className="w-full h-auto object-cover"
              src={slide.src}
              alt={slide.alt}
            />
          </div>
        ))}
      </Slider>
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-2 md:left-4 lg:left-8 transform -translate-y-1/2 bg-gray-800 bg-opacity-70 text-white px-2 py-4 sm:px-3 sm:py-6 md:px-3 md:py-6 lg:px-3 lg:py-8 rounded-md shadow-lg flex items-center justify-center"
        aria-label="Previous Slide"
      >
        <span className="text-base sm:text-lg md:text-xl"> &lt; </span>
      </button>
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-2 md:right-4 lg:right-8 transform -translate-y-1/2 bg-gray-800 bg-opacity-70 text-white px-2 py-4 sm:px-3 sm:py-6 md:px-3 md:py-6 lg:px-3 lg:py-8 rounded-md shadow-lg flex items-center justify-center"
        aria-label="Next Slide"
      >
        <span className="text-base sm:text-lg md:text-xl"> &gt; </span>
      </button>
    </div>
  );
};

export default HeroImageSlider;
