import fullcreammilk1 from '../../assets/ProductsList/FullCreamMIlk/FullCreamMilk1.jpg'
import fullcreammilk2 from '../../assets/ProductsList/FullCreamMIlk/FullCreamMilk1.jpg'
import fullcreammilk3 from '../../assets/ProductsList/FullCreamMIlk/FullCreamMilk1.jpg'
import fullcreammilk4 from '../../assets/ProductsList/FullCreamMIlk/FullCreamMilk1.jpg'

const FullCreamMilkProducts = [
  { id: 4, image: fullcreammilk1, name: 'Full Cream Milk 1', rating: 4.7, mrp: '₹300', discount: 10 },
  { id: 5, image: fullcreammilk2, name: 'Full Cream Milk 2', rating: 4.2, mrp: '₹250', discount: 10 },
  { id: 6, image: fullcreammilk3, name: 'Full Cream Milk 3', rating: 4.2, mrp: '₹270', discount: 10 },
  { id: 7, image: fullcreammilk4, name: 'Full Cream Milk 4', rating: 4.2, mrp: '₹290', discount: 10 },
];

export default FullCreamMilkProducts;
