import React, { useState } from 'react';
import HomeProductList from './HomeProductList'; // Adjust path if needed
import HomePageProductCatalog from './HomePageProductCatalog'; // Adjust path if needed

const NewArrivalList = () => {
  const newArrivalProducts = HomeProductList.filter(product => product.isNewArrival);
  const [viewAll, setViewAll] = useState(false);

  const handleViewAllClick = () => {
    setViewAll(true);
  };

  const showViewAll = newArrivalProducts.length > 4;

  return (
    <div className="px-4 py-5 mx-auto max-w-screen-xl md:mr-17 md:ml-17 relative">
      <div className="flex flex-col md:flex-row justify-between items-center mb-6">
        <h2 className="text-xl ml-4 font-bold text-center md:text-left">New Arrivals</h2>
        {showViewAll && !viewAll && (
          <button
            onClick={handleViewAllClick}
            className="hidden md:block px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            View All
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
        {newArrivalProducts.slice(0, 4).map(product => (
          <HomePageProductCatalog
            key={product.id}
            image={product.image}
            name={product.name}
            rating={product.rating}
            price={product.price}
          />
        ))}
      </div>
      {showViewAll && !viewAll && (
        <div className="md:hidden flex justify-center mt-4">
          <button
            onClick={handleViewAllClick}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            View All
          </button>
        </div>
      )}
      {viewAll && (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
          {newArrivalProducts.slice(4).map(product => (
            <HomePageProductCatalog
              key={product.id}
              image={product.image}
              name={product.name}
              rating={product.rating}
              price={product.price}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default NewArrivalList;
