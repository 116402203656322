import cheese1 from '../../assets/Image1.jpg'
import cheese2 from '../../assets/Image1.jpg'

const CheeseImages = [
  {
    src: cheese1,
    alt: "Cheese Slide 1",
  },
  {
    src: cheese2,
    alt: "Cheese Slide 2",
  },
  // Add more cheese product images...
];

export default CheeseImages;
