import React, { useState } from 'react';
import { FaList, FaTh } from 'react-icons/fa';
import SortBy from './SortBy'; // Import the SortBy component
import useWindowSize from '../useWindowSize' // Import the useWindowSize hook

const HeroCatalogHeader = ({ headerText, viewMode, setViewMode, sorting, setSorting }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768; // Adjust the breakpoint as needed
  const [isSortingOpen, setIsSortingOpen] = useState(false);

  const toggleSorting = () => setIsSortingOpen(!isSortingOpen);

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
  };

  return (
    <div className="container mb-2 mx-auto px-1 sm:px-4 lg:px-7 xl:max-w-7xl mb-10 p-4 border rounded-lg shadow-md bg-white">
      <div className="flex flex-wrap items-center justify-between">
        <div className="page-item-info flex items-center mb-2 md:mb-0">
          <h1 className="text-lg md:text-xl mr-2">{headerText}</h1>
          <span className="text-gray-500">9 Items</span>
        </div>
        <div className="flex items-center">
          <div className="viewby flex items-center mr-2">
            {isMobile ? (
              <>
                <button
                  className={`view-btn p-1 md:p-2 border rounded-l ${viewMode === 'list' ? 'bg-gray-200' : ''}`}
                  onClick={() => handleViewModeChange('grid')}
                >
                  <FaList />
                </button>
                <button
                  className={`view-btn p-1 md:p-2 border rounded-r ${viewMode === 'grid' ? 'bg-gray-200' : ''}`}
                  onClick={() => handleViewModeChange('list')}
                >
                  <FaTh />
                </button>
              </>
            ) : (
              <>
                <button
                  className={`view-btn p-1 md:p-2 border rounded-l ${viewMode === 'list' ? 'bg-gray-200' : ''}`}
                  onClick={() => handleViewModeChange('list')}
                >
                  <FaList />
                </button>
                <button
                  className={`view-btn p-1 md:p-2 border rounded-r ${viewMode === 'grid' ? 'bg-gray-200' : ''}`}
                  onClick={() => handleViewModeChange('grid')}
                >
                  <FaTh />
                </button>
              </>
            )}
          </div>
          {/* Show SortBy only on desktop */}
          <div className="hidden md:block">
            <SortBy
              sorting={sorting}
              setSorting={setSorting}
              isSortingOpen={isSortingOpen}
              toggleSorting={toggleSorting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCatalogHeader;
