import milk1 from '../../assets/SliderImageList/MilkSlider/Milk1.jpg';
import milk2 from '../../assets/SliderImageList/MilkSlider/Milk2.jpg';
import milk3 from '../../assets/SliderImageList/MilkSlider/Milk1.jpg';



const MilkImages = [
  {
    src: milk1,
    alt: "Milk Slide 1",
  },
  {
    src: milk2,
    alt: "Milk Slide 2",
  },
  {
    src: milk3,
    alt: "Milk Slide 3",
  },
  
  // Add more milk product images...
];

export default MilkImages;
