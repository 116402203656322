import React from 'react';
import BestSellerList from './BestSellerList'; // Adjust path
import NewArrivalList from './NewArrivalList'; // Adjust path

const HomePageProductList = () => {
  return (
    <div>
      <BestSellerList />
      <NewArrivalList />
    </div>
  );
};

export default HomePageProductList;
